import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Line } from "../../";
import * as s from "./hero.module.css";

const ContactHero = () => {
  const d = useStaticQuery(graphql`
    query contactHero {
      sanityContact {
        heroTitle
        heroDescription
      }
    }
  `).sanityContact;

  return (
    <section className={s.section}>
      <h1 className={s.title}>{d.heroTitle}</h1>
      <p className={s.subtitle}>Get in touch</p>
      <Line className={s.line} />
      <p className={s.description}>{d.heroDescription}</p>
    </section>
  )
}

export default ContactHero;