import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Line } from "../../";
import * as s from "./response.module.css";

const ContactResponse = () => {
  const d = useStaticQuery(graphql`
    query contactSuccess {
      sanityContact {
        successTitle
        successDescription
      }
    }
  `).sanityContact;
  return (
    <section className={s.section}>
      <h1 className={s.title}>{d.successTitle}</h1>
      <Line className={s.line} />
      <p className={s.description}>{d.successDescription}</p>
    </section>
  )
}
export default ContactResponse