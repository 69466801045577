// extracted by mini-css-extract-plugin
export var consent = "form-module--consent--7dedb";
export var error = "form-module--error--2d4fd";
export var errorTitle = "form-module--error-title--9baea";
export var errorWrapper = "form-module--error-wrapper--1de4d";
export var form = "form-module--form--b5c4e";
export var formWrapper = "form-module--form-wrapper--e1070";
export var help = "form-module--help--4f09c";
export var input = "form-module--input--c5d75";
export var inputWrapper = "form-module--input-wrapper--88dd3";
export var label = "form-module--label--80f43";
export var scaleIn = "form-module--scale-in--60fd7";
export var warning = "form-module--warning--364c1";