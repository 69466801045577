import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Button } from "../../";
import Gdpr from "./gdpr";
import * as s from "./form.module.css";

const endpoint = "https://ksbilqs699.execute-api.eu-central-1.amazonaws.com/dev/feedback-mailing";

const ContactForm = ({onSent}) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [time, setTime] = useState(0);
  const [sending, setSending] = useState(false);
  const [errorCode, setError] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setTime(time + 1), 1000);
    return () => window.clearInterval(interval)
  }, [time])

  const onSubmit = (e) => {
    e.preventDefault();
    if (sending) return;
    setSending(true);
    setError(false);

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    
    if (!email) {
      setSending(false);
      setError(1);
      return false;
    }
    if (!email.match(emailRegex)) {
      setSending(false);
      setError(2);
      return false;
    }
    if (!message) {
      setSending(false);
      setError(3);
      return false;
    }

    const formData = {email, phone, company, message, time};

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(res => {
      console.log(res);

      setSending(false);
      if (res.ok) {
        onSent()
        return res.json()
      } else {
        setError(res.status);
        return null
      }
    })
    .then(data => console.log(data))
    .catch(err => {
      setSending(false);
      console.log(err)
    })
  }
  
  return (
    <section className={s.formWrapper}>
      <form className={s.form} onSubmit={onSubmit}>
        <Input type="email" label={"Your email address"} placeholder={"Email address"} value={email} setValue={setEmail} />
        <Input type="tel" label={"Your phone"} placeholder={"Phone number"} value={phone} setValue={setPhone} h={true} />
        <Input type="text" label={"Your company"} placeholder={"Company"} value={company} setValue={setCompany} h={true} />
        <Textarea label={"Your message"} placeholder={"Message"} value={message} setValue={setMessage} />
        <ErrorMessage code={errorCode} />
        <Button label="Send message" component="button" type="submit" />
        <Gdpr />
      </form>
    </section>
  )
}

const Input = ({type, label, value, setValue, placeholder, h}) => {
  const onChange = (e) => {
    setValue(e.target.value)
  }
  
  return (
    <div className={`${s.inputWrapper} ${h ? s.help : "" }`}>
      <label className={s.label}>{label}</label>
      <input
        className={s.input}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}

const Textarea = ({label, value, setValue, placeholder}) => {
  const onChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className={s.inputWrapper}>
      <label className={s.label}>{label}</label>
      <textarea
        className={s.input}
        value={value}
        onChange={onChange}
        rows={3}
        placeholder={placeholder}
      />
    </div>
  )
}

const ErrorMessage = ({code}) => {
  const d = useStaticQuery(graphql`
    query formErrorsQuery {
      sanityContact {
        emptyEmailTitle
        emptyEmailDescription
        invalidEmailTitle
        invalidEmailDescription
        emptyMessageTitle
        emptyMessageDescription
        spamTitle
        spamDescription
        serverErrorTitle
        serverErrorDescription
      }
    }
  `).sanityContact;

  let typeStyle, title, message;
  switch (code) {
    case 1 :
      typeStyle = s.warning;
      title = d.emptyEmailTitle;
      message = d.emptyEmailDescription;
      break;
    case 2 :
      typeStyle = s.warning;
      title = d.invalidEmailTitle;
      message = d.invalidEmailDescription;
      break;
    case 3 :
      typeStyle = s.warning;
      title = d.emptyMessageTitle;
      message = d.emptyMessageDescription;
      break;
    case 403 :
      typeStyle = s.warning;
      title = d.spamTitle;
      message = d.spamDescription;
      break;
    case 500 :
      typeStyle = s.error;
      title = d.serverErrorTitle;
      message = d.serverErrorDescription;
      break;
    default :
      return null;
  }
  return (
    <div key={code} className={`${s.errorWrapper} ${typeStyle}`}>
      <h2 className={s.errorTitle}>{title}</h2>
      <p>{message}</p>
    </div>
  )
}

export default ContactForm