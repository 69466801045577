import React, {useState, useEffect} from "react";
import { graphql } from "gatsby";
import {Layout} from "../components";
import {Hero, Form, Response} from "../components/contactPage";


const ContactPage = ({data}) => {
  const {seoTitle, seoDescription} = data.sanityContact;
  const [isSent, setIsSent] = useState(false)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
  })
  })
  return (
    <Layout
      seo={{
        title: seoTitle,
        description: seoDescription
      }}
    >
      {isSent ? (
        <Response />
      ) : (
        <>
          <Hero />
          <Form onSent={() => setIsSent(true)} />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactsPageQuery {
    sanityContact {
      seoTitle
      seoDescription
    }
  }
`

export default ContactPage