import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as s from "./form.module.css";

export default function Gdpr () {
  const { gdpr } = useStaticQuery(graphql`
    query formGDPRQuery {
      sanityContact {
        gdpr
      }
    }
  `).sanityContact;

  return (
    <p className={s.consent}>{gdpr}</p>
  )
}